.swiper-container-fade {
  &.swiper-container-free-mode {
    .swiper-slide {
      transition-timing-function: ease-out;
    }
  }
  .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
    .swiper-slide {
      pointer-events: none;
    }
  }
  .swiper-slide-active {
    &,
    & .swiper-slide-active {
      pointer-events: auto;
    }
  }
}
